import traits from './traits.json';

export const rules = {
  "Decoder Goggles": ["Crown", "Red Partyhat", "Seilor Hat", "Red Seiyan", "Gold Seiyan", "Chef Hat", "Pirate Hat","Curated - TOSHI Mask"],
  "Laser Eyes": ["Crown", "Gold Seiyan", "Red Seiyan", "Blunt", "Bone", "Cigar", "Flame", "Golden Bone", "Paint Brush", "Mustache", "Trident"],
  "3D Glasses": ["Pirate Hat", "Seilor Hat", "Crown","Curated - TOSHI Mask"],
  "Red Partyhat": ["Ellie's Shades"],
  "Crown": ["Decoder Goggles", "Laser Eyes", "3D Glasses", "Ellie's Shades", "Robin Mask", "Sunglasses"],
  "Red Partyhat": ["Decoder Goggles"],
  "Seilor Hat": ["Decoder Goggles", "3D Glasses"],
  "Red Seiyan": ["Decoder Goggles", "Laser Eyes"],
  "Gold Seiyan": ["Decoder Goggles", "Laser Eyes"],
  "Chef Hat": ["Decoder Goggles"],
  "Pirate Hat": ["Decoder Goggles", "3D Glasses"],
  "Blunt": ["Laser Eyes"],
  "Bone": ["Laser Eyes"],
  "Cigar": ["Laser Eyes"],
  "Flame": ["Laser Eyes"],
  "Golden Bone": ["Laser Eyes"],
  "Paint Brush": ["Laser Eyes"],
  "Mustache": ["Laser Eyes"],
  "Trident": ["Laser Eyes"],
  "Ellie's Shades": ["Red Partyhat", "Crown"],
  "Robin Mask": ["Crown"],
  "Sunglasses": ["Crown","Curated - TOSHI Mask"]
};

export default traits;
